import Vue from 'vue'
import Vuex from 'vuex'
import { apolloClient } from "@/vue-apollo";
import getSurveyByNumber from '@/graphql/getSurveyByNumber.gql'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    surveyNumber: ""
  },
  getters: {
    getSurveyId: (state) => state.surveyNumber
  },
  mutations: {
    setSurveyId: (state, data) => { state.surveyNumber = data}
  },
  actions: {
		async fetchSurveyByNumber(context, surveyNumber) {
			await apolloClient.query({
				fetchPolicy: "no-cache",
				query: getSurveyByNumber,
				variables: {
					surveyNumber: surveyNumber
				}
			})
				.then((response) => {
					if (response.data.getPrivateSurvey.status == 200) {
						context.commit("setSurveyId", response.data.getPrivateSurvey.uniqueId);
					}
					else {

						Vue.swal({
							icon: "error",
							title: "Oops...",
							text: "Survey Does'nt exist!",
							showConfirmButton: false,
							timer: 1500,
						});

					}


				})

		},
  },
  modules: {
  }
})
