<template>
 <v-app>
    <v-container fluid>
</v-container>
</v-app>
</template>

<script>
export default {

}
</script>

<style>

</style>