import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VNavigationDrawer,{attrs:{"app":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,[_c(VListItem,[_c(VListItemAvatar,[_c('img',{attrs:{"src":require("@/assets/img/logo.png"),"alt":"Logo"}})]),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"title"},[_vm._v("eDodoso")])],1)],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":""}},_vm._l((_vm.items),function([icon, text, link],i){return _c(VListItem,{key:i,attrs:{"link":""},on:{"click":function($event){return _vm.$vuetify.goTo(link)}}},[_c(VListItemIcon,{staticClass:"justify-center"},[_c(VIcon,[_vm._v(_vm._s(icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"subtitile-1"},[_vm._v(_vm._s(text))])],1)],1)}),1)],1),_c(VAppBar,{staticClass:"px-10",class:{ expand: _vm.flat },attrs:{"app":"","color":_vm.color,"flat":_vm.flat,"dark":""}},[_c(VToolbarTitle,[_c(VImg,{attrs:{"src":require("@/assets/img/logo.png"),"max-width":"50px"}})],1),_c(VSpacer),(_vm.isXs)?_c(VAppBarNavIcon,{staticClass:"mr-4",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_c('div',[_c(VRow)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }