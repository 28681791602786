import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VFooter,{attrs:{"dark":"","padless":""}},[_c(VCard,{staticClass:"white--text text-center",attrs:{"flat":"","tile":"","color":"#005464"}},[_c(VCardText,_vm._l((_vm.icons),function(icon,i){return _c(VBtn,{key:i,staticClass:"mx-4 white--text",attrs:{"href":icon.link,"target":"_blank","icon":""}},[_c(VIcon,{attrs:{"size":"24px"}},[_vm._v(_vm._s(icon.text))])],1)}),1),_c(VCardText,{staticClass:"white--text pt-0"},[_vm._v(" This product was designed and maintained by e-Government Authority Tanzania ")]),_c(VDivider),_c(VCardText,{staticClass:"white--text"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("eGA - All Rights reserved")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }