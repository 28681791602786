import Vue from 'vue'
import VueRouter from 'vue-router'
import PublicSurvey from '../components/PublicSurvey.vue'
import home from "../components/HomeSection.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/public_survey_url',
    name: 'Public Survey',
    component: PublicSurvey
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
