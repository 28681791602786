<template>
  <section id="hero">
    <v-row>
      <v-row align="center" justify="center">
        <v-col cols="4" md="6" xl="4"> </v-col>
        <v-col cols="8" md="6" xl="8" class="hidden-sm-and-down mt-10"> </v-col>
      </v-row>
    </v-row>
    <div class="flex justify-center mt-6">
            <h1 class="font-bold display-1 txt-primary  alignment-item-center justify-content-center mt-2">
             Collect Data, Analyze and Generate Reports
            </h1>
    </div>
    <!-- <v-divider class="border-white mt-12 mb-24"></v-divider> -->

    <div class="flex flex-row justify-center mt-12">
      <div class="border border-2 w-full mx-8 md:w-1/2 bg-white rounded-md">
        <div class="flex flex-row">
          <input
            type="text"
            v-model="surveyNumber"
            class="border-0 p-2 focus:outline-none grow"
            placeholder="Enter survey number"
          />
          <v-btn class="bg-primary grow-0 m-2" @click="goToSurvey"
            ><v-icon color="white">mdi-open-in-new</v-icon
            ><span class="text-white">Open</span></v-btn
          >
        </div>
      </div>
    </div>

    <div class="mb-24">
      <!-- First 3 Cards -->

      <div class="flex flex-col md:flex-row justify-center md:my-8">
        <div class="w-full my-2 md:my-0 md:w-1/5">
          <system-card
            :logo-type="'dodoso_pro'"
            :systemName="'PRO'"
            :system-url="'https://edodoso.gov.go.tz/'"
          ></system-card>
        </div>
        <div class="w-full my-2 md:my-0 md:w-1/5">
          <system-card
            :logo-type="'dodoso_lite'"
            :systemName="'LITE'"
            :system-url="'https://edodosov2.all.co.tz/'"
          ></system-card>
        </div>
        <div class="w-full my-2 md:my-0 md:w-1/5">
          <system-card
            :logo-type="'eUkaguzi'"
            :systemName="'eUkaguzi'"
            :system-url="'https://ukaguzi.edodoso.gov.go.tz/'"
          ></system-card>
        </div>
      </div>


      <!-- second 2 Cards -->
      <div class="flex flex-col md:flex-row justify-center mt-8">
        <div class="w-full my-2 md:my-0 md:w-1/5">
          <system-card
            :logo-type="'public_survey'"
            :systemName="'Public Survey'"
            link
            :to="{name:`Public Survey`}"

          ></system-card>
        </div>
        <div class="w-full my-2 md:my-0 md:w-1/5">
          <system-card
            :logo-type="'online_quiz'"
            :systemName="'Online Quiz'"
            :system-url="'https://quiz.edodoso.gov.go.tz/'"
          ></system-card>
        </div>
      </div>

      
    </div>
    <div class="svg-border-waves"></div>
  </section>
</template>

<script>
// import about from "../components/AboutSection.vue";
import SystemCard from "./SystemCard.vue";
export default {
  components: { SystemCard },
  data() {
    return {
      surveyNumber: "",
    };
  },
  methods: {
   async goToSurvey() {
      if (this.surveyNumber.length === 6 && parseInt(this.surveyNumber)) {
        window.open(
          "https://edodoso.gov.go.tz/index.php/" +
            this.surveyNumber +
            "?lang=en"
        )
      } else {
        // Handle public survey for dodoso lite
      await this.$store.dispatch('fetchSurveyByNumber', this.surveyNumber);
      window.open('https://edodosov2.all.co.tz/' + 'response/'+ this.$store.getters.getSurveyId)
      }
    },
  },
};
</script>

<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>

<style>
section {
  position: relative;
}
</style>
