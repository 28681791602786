<template>
  <div
    class="
      flex flex-col
      mx-4
      p-8
      bg-white
      rounded-lg
      shadow-sm
      hover:cursor-pointer hover:shadow-lg
      items-center
      h-52
      justify-space-between
    "
    v-on:click="onClickHandler"
  >
    <div v-if="logoType == 'dodoso_pro'">
      <v-img
        src="@/assets/img/dodoso_pro.png"
        class="d-block"
        max-height="80"
      />
    </div>
    <div v-if="logoType == 'dodoso_lite'">
      <v-img
        src="@/assets/img/dodoso_lite.png"
        max-width="220px"
        max-height="90"
      />
    </div>
    <div v-if="logoType == 'eUkaguzi'">
      <v-img
        src="@/assets/img/ekaguzi.png"
        max-width="250px"
        class="mt-2"
        max-height="90"
      />
    </div>
    <div v-else-if="logoType == 'public_survey'">
      <v-icon class="txt-primary" size="80"> mdi-earth </v-icon>
    </div>
    <div v-else-if="logoType == 'online_quiz'">
      <v-icon class="txt-primary" size="80"> mdi-help-network </v-icon>
    </div>

    <h2 class="h2 font-bold txt-primary mt-8 text-2xl text-center">
      {{ systemName }}
    </h2>
  </div>
</template>

<script>
export default {
  name: "SystemCard",
  props: {
    logoType: {
      type: String,
    },
    systemName: {
      type: String,
    },
    systemUrl: {
        type: String
    }
  },
  data() {
    return {
        routeCards: ['dodoso_pro', 'dodoso_lite', 'eUkaguzi', 'online_quiz',]
    }
  },
  methods: {
    onClickHandler() {
        if (this.routeCards.find((routeCard) => routeCard == this.logoType)) {
            window.open(this.systemUrl, '__blank')
        } else {
            // Handle public surveys
            this.$router.push("/public_surveys")
        }
    }
  }
};
</script>
