import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('navigation',{attrs:{"color":_vm.color,"flat":_vm.flat}}),_c(VMain,{staticClass:"pt-0"},[_c('router-view')],1),_c(VScaleTransition,[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"},{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"secondary"},on:{"click":_vm.toTop}},[_c(VIcon,[_vm._v("mdi-arrow-up")])],1)],1),_c('foote')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }