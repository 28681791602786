<template>
  <v-footer dark padless>
    <v-card flat tile class="white--text text-center" color="#005464">
      <v-card-text>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-4 white--text"
          :href="icon.link"
          target="_blank"
          icon
        >
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        This product was designed and maintained by e-Government Authority Tanzania 
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>eGA - All Rights reserved</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "https://www.facebook.com/people/tzegovernment/100064451301490/",
      },
      {
        text: "mdi-twitter",
        link: "https://www.tumblr.com/tzegovernment",
      },
      {
        text: "mdi-youtube",
        link: "https://www.youtube.com/channel/UCqaVDGdr37sctTwNS3Lq_Uw",
      },
      {
        text: "mdi-instagram",
        link: "https://www.instagram.com/tzegovernment/",
      },
      {
        text: "mdi-help",
        link: "https://helpdesk.gov.go.tz/login/",
      },
    ],
  }),
};
</script>
